import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'
import IconInstagram from './svg-icons/IconInstagram.vue'
import IconLinkedIn from './svg-icons/IconLinkedIn.vue'
import IconTwitter from './svg-icons/IconTwitter.vue'
import IconWebsite from './svg-icons/IconWebsite.vue'

interface TeamMember {
  avatar: string
  avatarMobile: string
  name: string
  role: string
  linkInstagram?: string
  linkLinkedIn?: string
  linkTwitter?: string
  linkWebsite?: string
  bio: string
}

@Component({
  components: {
    IconInstagram,
    IconLinkedIn,
    IconTwitter,
    IconWebsite
  }
})
export default class WebsiteTeamMember extends BaseMixin {
  @Prop({ type: Object}) readonly member!: TeamMember
}
